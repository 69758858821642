import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import requests from "../../../services/requests";
import Card from "../../../components/Cards";
import Layout from "../../../components/Layout";
import TitlePurple from "../../../components/Typography/TitlePurple";
import SEO from "../../../components/SEO";
import Paginate from "../../../components/Paginate/Paginate";
import { Link } from "gatsby";
import moment from "moment";
import getProfile from "../../../services/profile";

const ProcessesMapPage = props => {
    const [actionComment, setActionComment] = useState(false);
    const [actionUpdate, setActionUpdate] = useState(false);
    const [metaComment, setMetaComment] = useState({ current_page: 1 });
    const [metaUpdate, setMetaUpdate] = useState({ current_page: 1 });
    const [updatesList, setUpdatesList] = useState();
    const [commentsList, setCommentsList] = useState();
    const [loadComments, setLoadComments] = useState(false);
    const [loadUpdates, setLoadUpdates] = useState(false);

    const listComments = () => {
        setLoadComments(true);
        requests.lastComments({}, metaComment.current_page > 0 ? metaComment.current_page : 1).then(response => {
            setCommentsList(response.data);
            setMetaComment(response.meta);
            setLoadComments(false);
        });
    };

    const listUpdates = () => {
        setLoadUpdates(true);
        requests.listUpdates({}, metaUpdate.current_page > 0 ? metaUpdate.current_page : 1).then(response => {
            setUpdatesList(response.data);
            setMetaUpdate(response.meta);
            setLoadUpdates(false);
        });
    };

    const readComment = entity => {
        requests
            .editProcessComment(entity.processIdentifier, entity.identifier, {
                description: entity.description,
                hasBeenRead: 1,
            })
            .then(response => {
                listComments();
            });
    };

    useEffect(() => {
        listComments();
    }, [actionComment]);

    useEffect(() => {
        listUpdates();
    }, [actionUpdate]);

    return (
        <Layout>
            <SEO title="Comentários e atualizações" />
            <div className="flex">
                <div className="w-6/12">
                    <div className="p-4">
                        <Card>
                            <TitlePurple className="text-header uppercase text-roxo_oficial font-medium w-6/12">
                                Comentários
                            </TitlePurple>
                            <div className="border-C2 border-solid pt-2 pb-12 overflow-hidden">
                                <main className="border-t overflow-auto" id="table-responsive">
                                    <div className="flex">
                                        <table id="table-responsive" className="table-auto w-full text-left mb-6">
                                            <tbody>
                                                {loadComments ? (
                                                    <div className="flex flex-col items-center">
                                                        <ClipLoader size={20} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {commentsList &&
                                                            commentsList?.map(comment => (
                                                                <>
                                                                    <tr className="border-solid border-C2 border-b">
                                                                        <Link
                                                                            to={`/processos/detalhes/${comment.processIdentifier}?tab=comment`}
                                                                            state={{ prevPath: window.location.pathname }}
                                                                        >
                                                                            <legend className="float-right">
                                                                                {moment(comment.createdAt).format(
                                                                                    "HH:mm - DD MMM YYYY"
                                                                                )}
                                                                            </legend>
                                                                            <h5 className="mt-3">{comment.processCode}</h5>
                                                                            <p
                                                                                className="font-normal"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: comment.description,
                                                                                }}
                                                                                style={{ display: "contents" }}
                                                                            />
                                                                            {comment.userRole === "Client" &&
                                                                                comment.hasBeenRead &&
                                                                                getProfile.role !== "ROLE_CLIENT" && (
                                                                                    <strong
                                                                                        className={`text-xs text-roxo_oficial`}
                                                                                    >
                                                                                        {" "}
                                                                                        (lida)
                                                                                    </strong>
                                                                                )}
                                                                        </Link>
                                                                        {comment.userRole === "Client" &&
                                                                            !comment.hasBeenRead &&
                                                                            getProfile.role !== "ROLE_CLIENT" && (
                                                                                <strong
                                                                                    onClick={() => {
                                                                                        readComment(comment);
                                                                                    }}
                                                                                    className="float-right cursor-pointer underline text-roxo_oficial mb-1 mr-2"
                                                                                    id="remove-notification"
                                                                                >
                                                                                    {" "}
                                                                                    (Marcar como lida)
                                                                                </strong>
                                                                            )}
                                                                    </tr>
                                                                </>
                                                            ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </main>
                                {metaComment.total_pages > 1 && (
                                    <Paginate
                                        meta={metaComment}
                                        setMeta={setMetaComment}
                                        action={actionComment}
                                        setAction={setActionComment}
                                    />
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="w-6/12">
                    <div className="p-4">
                        <Card>
                            <TitlePurple className="text-header uppercase text-roxo_oficial font-medium w-6/12">
                                Atualizações
                            </TitlePurple>
                            <div className="border-C2 border-solid pt-2 pb-12 overflow-hidden">
                                <main className="border-t overflow-auto" id="table-responsive">
                                    <div className="flex">
                                        <table id="table-responsive" className="table-auto w-full text-left mb-6">
                                            <tbody>
                                                {loadUpdates ? (
                                                    <div className="flex flex-col items-center">
                                                        <ClipLoader size={20} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {updatesList &&
                                                            updatesList?.map(update => (
                                                                <>
                                                                    <tr className="border-solid border-C2 border-b">
                                                                        <Link
                                                                            to={`/processos/detalhes/${update.processIdentifier}?tab=update`}
                                                                            state={{ prevPath: window.location.pathname }}
                                                                        >
                                                                            <legend className="float-right">
                                                                                {moment(update.createdAt).format(
                                                                                    "HH:mm - DD MMM YYYY"
                                                                                )}
                                                                            </legend>
                                                                            <h5 className="mt-3">{update.processCode}</h5>
                                                                            <p
                                                                                className="font-normal"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: update.description,
                                                                                }}
                                                                                style={{ display: "contents" }}
                                                                            />
                                                                        </Link>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </main>
                                {metaUpdate.total_pages > 1 && (
                                    <Paginate
                                        meta={metaUpdate}
                                        setMeta={setMetaUpdate}
                                        action={actionUpdate}
                                        setAction={setActionUpdate}
                                    />
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ProcessesMapPage;
